<template>
  <div>
    <v-row>
      <v-col>
        <UserWelcomeent />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <div class="mb-4" v-if="$vuetify.breakpoint.mobile">
          <v-alert text icon="mdi-whatsapp" :color="'primary'">
            <small>Precisando de ajuda?</small>
            <br />
            <span>Estamos disponíveis no</span>
            <b>WhatsApp!</b>
            <br />
            <v-btn class="mt-2" outlined color="primary" @click="getHelp()">
              Falar com especialista!
            </v-btn>
          </v-alert>
        </div>
        <DashboardSessionStats />
        <FirstSteps v-if="showOnboarding" />
        <DashboardNextSessions />
      </v-col>
      <v-col>

        <div class="mb-4" v-if="!$vuetify.breakpoint.mobile">
          <v-alert text icon="mdi-whatsapp" :color="'primary'">
            <small>Precisando de ajuda?</small>
            <br />
            <span>Estamos disponíveis no</span>
            <b>WhatsApp!</b>
            <br />
            <v-btn class="mt-2" outlined color="primary" @click="getHelp()">
              Falar com especialista!
            </v-btn>
          </v-alert>
        </div>
        <DashboardBirthdays />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DashboardSessionStats from "../components/dashboard/sections/DashboardSessionStats.vue";
import UserWelcomeent from "../components/dashboard/sections/UserWelcomeent.vue";
import FirstSteps from "../components/dashboard/sections/FirstSteps.vue";
import DashboardBirthdays from "@/components/dashboard/sections/DashboardBirthdays.vue";
import DashboardNextSessions from "@/components/dashboard/sections/DashboardNextSessions.vue";
import { differenceInDays, parseISO, formatISO } from "date-fns";

export default {
  components: {
    DashboardNextSessions,
    DashboardSessionStats,
    DashboardBirthdays,
    FirstSteps,
    UserWelcomeent,
  },

  data() {
    return {};
  },

  computed: {
    company() {
      return this.$store.state.auth.company;
    },


    showOnboarding() {
      return (
        differenceInDays(
          parseISO(formatISO(new Date())),
          parseISO(this.company.created_at)
        ) < 7
      );
    },
  },

  methods: {
    getHelp() {
      let link = `https://api.whatsapp.com/send?phone=+${process.env.VUE_APP_SUPPORT_WHATSAPP}`;

      let msg = "&text=Olá, Preciso de ajuda!";

      window.open(link + msg);
    },
  },
};
</script>
