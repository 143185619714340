<template>
  <v-card class="mb-4" outlined style="position: relative; border-radius: 16px">
    <v-card-title>
      <h4>Este mês:</h4>
    </v-card-title>
    <v-card-text>
      <div class="d-flex flex-column flex-md-row justify-space-around">
        <div class="d-flex py-2">
          <div>
            <v-avatar>
              <v-img src="@/assets/images/dashboard/icon-1.svg" />
            </v-avatar>
          </div>
          <div class="px-4">
            <div>
              <span class="text-secondary">Sessões:</span>
              <br />
              <h3 class="font-weight-bold">{{ stats.sessions }}</h3>
            </div>
          </div>
        </div>
        <v-divider vertical />
        <div class="d-flex py-2">
          <div>
            <v-avatar>
              <v-img src="@/assets/images/dashboard/icon-2.svg" />
            </v-avatar>
          </div>
          <div class="px-4">
            <div>
              <span class="text-secondary">Atendidas:</span>
              <br />
              <h3 class="font-weight-bold">{{ stats.shown }}</h3>
            </div>
          </div>
        </div>
        <v-divider vertical />
        <div class="d-flex py-2">
          <div>
            <v-avatar>
              <v-img src="@/assets/images/dashboard/icon-3.svg" />
            </v-avatar>
          </div>
          <div class="px-4">
            <div>
              <span class="text-secondary">Faltas:</span>
              <br />
              <h3 class="font-weight-bold">{{ stats.missed }}</h3>
            </div>
          </div>
        </div>
      </div>
    </v-card-text>
    <!--     <v-card-title
      ><app-icon color="primary" class="mr-3">bar_chart</app-icon> Este mês:
    </v-card-title>
    <v-card-text>
      <div
        class="d-flex flex-column flex-md-row justify-space-between"
        style="width: 80%"
      >
        <div class="d-flex py-2">
          <div>
            <v-avatar color="#EDEAFC">
              <app-icon color="primary">event</app-icon>
            </v-avatar>
          </div>
          <div class="px-4">
            <div>
              <span class="text-secondary">Sessões:</span>
              <br />
              <h2 class="font-weight-bold">{{ stats.sessions }}</h2>
            </div>
          </div>
        </div>

        <div class="d-flex py-2">
          <div>
            <v-avatar color="#E6F2E6">
              <app-icon color="success">done</app-icon>
            </v-avatar>
          </div>
          <div class="px-4">
            <div>
              <span class="text-secondary">Atendidas:</span>
              <br />
              <h2 class="font-weight-bold">{{ stats.shown }}</h2>
            </div>
          </div>
        </div>

        <div class="d-flex py-2">
          <div>
            <v-avatar color="#FBE7E7">
              <app-icon color="error">error</app-icon>
            </v-avatar>
          </div>
          <div class="px-4">
            <div>
              <span class="text-secondary">Faltas:</span>
              <br />
              <h2 class="font-weight-bold">{{ stats.missed }}</h2>
            </div>
          </div>
        </div>
      </div>
      <div style="width: 20%">
        <div class="image-ilustration">
          <v-img
            eager
            contain
            height="180"
            src="../../../assets/images/boneca02_small.png"
          />
        </div>
      </div>
    </v-card-text> -->
  </v-card>
</template>

<script>

import { formatISO, startOfMonth, lastDayOfMonth, } from 'date-fns'

export default {

  data: () => ({
    params: {
      start: null,
      end: null,
    },

    stats: {
      sessions: 0,
      shown: 0,
      missed: 0,
    },
  }),

  created() {
    this.loadStats()

  },


  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },

  methods: {
    loadStats() {
      if (this.$acl.isProfessional()) {
        this.params.professional_id = [this.user.id];
      }
      this.params.start = formatISO(startOfMonth(new Date()), { representation: 'date' })
      this.params.end = formatISO(lastDayOfMonth(new Date()), { representation: 'date' })


      this.$http.index('sessions/session-stats', this.params).then(response => {
        this.stats = response.stats
      }).catch(error => {
        console.log(error)
      })
    },


  }

};
</script>

<style scoped>
.image-ilustration {
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>
